import { IMovement, IMovementRequest } from "@/types/movement";
import { IProductShipment } from "@/types/product_shipment";
import dayjs from "dayjs";
import { IStock } from "@/types/stock";
import { Stock } from "./stock";

export class Movement implements IMovement {
  public id;
  public movement_date;
  public quantity;
  public before_quantity;
  public current_stock_quantity;
  public buying_unit_price;
  public selling_unit_price;
  public transport_unit_price;
  public status;
  public memo;
  public lot_id;
  public product_id;
  public customer_product_id;
  public warehouse_id;
  public customer_id;
  public branch_id;
  public stock_id;
  public stockage_id;
  public order_id;
  public user_id;
  public lot_no;
  public warehouse_name;
  public before_warehouse_name;
  public after_warehouse_name;
  public before_stock_memo;
  public after_stock_memo;
  public customer_name;
  public branch_name;
  public product_name;
  public product_code;
  public product_unit;
  public customer_pn;
  public order_no;
  public shipment_id;
  public maker_id;
  public maker_name;
  public shipment_no;
  public invoice_no;
  public received_order_no;
  public is_estimated_shipment;
  public arrival_stock_id;
  public product_shipment: IProductShipment[];
  public arrival_stock: IStock;
  public previos_quantity;
  public exists_same_stock_id;
  public previous_stock_quantity;
  public created_at;
  public updated_at;
  public deleted_at;
  public status_disp;

  public constructor(params?: IMovement) {
    this.id = params ? params.id : 0;
    this.movement_date = params ? params.movement_date : "";
    this.quantity = params ? params.quantity : null;
    this.before_quantity = params ? params.before_quantity : null;
    this.current_stock_quantity = params ? params.current_stock_quantity : 0;
    this.buying_unit_price = params ? params.buying_unit_price : 0;
    this.selling_unit_price = params ? params.selling_unit_price : 0;
    this.transport_unit_price = params ? params.transport_unit_price : 0;
    this.status = params ? params.status : 0;
    this.memo = params ? params.memo : "";
    this.product_id = params ? params.product_id : 0;
    this.customer_product_id = params ? params.customer_product_id : 0;
    this.lot_id = params ? params.lot_id : 0;
    this.warehouse_id = params ? params.warehouse_id : null;
    this.customer_id = params ? params.customer_id : null;
    this.branch_id = params ? params.branch_id : null;
    this.stock_id = params ? params.stock_id : 0;
    this.stockage_id = params ? params.stockage_id : 0;
    this.order_id = params ? params.order_id : 0;
    this.user_id = params ? params.user_id : 0;
    this.lot_no = params ? params.lot_no : "";
    this.warehouse_name = params ? params.warehouse_name : "";
    this.before_warehouse_name = params ? params.before_warehouse_name : "";
    this.after_warehouse_name = params ? params.after_warehouse_name : "";
    this.before_stock_memo = params ? params.before_stock_memo : "";
    this.after_stock_memo = params ? params.after_stock_memo : "";
    this.customer_name = params ? params.customer_name : "";
    this.branch_name = params ? params.branch_name : "";
    this.product_name = params ? params.product_name : "";
    this.product_code = params ? params.product_code : "";
    this.product_unit = params ? params.product_unit : "";
    this.customer_pn = params ? params.customer_pn : "";
    this.order_no = params ? params.order_no : "";
    this.shipment_id = params ? params.shipment_id : 0;
    this.maker_id = params ? params.maker_id : 0;
    this.maker_name = params ? params.maker_name : "";
    this.shipment_no = params ? params.shipment_no : "";
    this.invoice_no = params ? params.invoice_no : "";
    this.product_shipment = params ? params.product_shipment : [];
    this.created_at = params
      ? dayjs(params.created_at).format("YYYY-MM-DD")
      : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";

    const status = ["", "入荷", "出荷", "移動"];
    this.status_disp = status[this.status];
    this.received_order_no = params ? params.received_order_no : "";
    this.is_estimated_shipment = params ? params.is_estimated_shipment : false;
    this.arrival_stock_id = params ? params.arrival_stock_id : 0;
    this.arrival_stock = params ? params.arrival_stock : new Stock();
    this.previos_quantity = params ? params.quantity : 0;
    this.exists_same_stock_id = params ? params.exists_same_stock_id : false;
    this.previous_stock_quantity = params ? params.previous_stock_quantity : 0;
  }
}

export class MovementRequest implements IMovementRequest {
  public arrival_date = "";
  public movement_date = "";
  public quantity: number | null = null;
  public buying_unit_price = 0;
  public selling_unit_price = 0;
  public status = 0;
  public memo: string | null = null;
  public lot_id: number | null = null;
  public product_id = 0;
  public warehouse_id: number | null = null;
  public customer_id: number | null = null;
  public branch_id: number | null = null;
  public stock_id = 0;
  public stockage_id: number | null = null;
  public customer_product_id: number | null = null;
  public supplier_id = 0;

  public constructor(params?: IMovementRequest) {
    if (!params) {
      return;
    }
    this.arrival_date = params.arrival_date;
    this.movement_date = params.movement_date;
    this.quantity = params.quantity;
    this.buying_unit_price = params.buying_unit_price;
    this.selling_unit_price = params.selling_unit_price;
    this.status = params.status;
    this.memo = params.memo;
    this.lot_id = params.lot_id;
    this.product_id = params.product_id;
    this.warehouse_id = params.warehouse_id;
    this.customer_id = params.customer_id;
    this.branch_id = params.branch_id;
    this.stock_id = params.stock_id;
    this.stockage_id = params.stockage_id;
    this.customer_product_id = params.customer_product_id;
  }

  /**
   * arrival_dateはstockからセットする
   * 主に編集時に利用
   *
   * @param movement
   */
  public createFromMovement(movement: IMovement): void {
    this.movement_date = movement.movement_date;
    this.quantity = movement.quantity;
    this.buying_unit_price = Number(movement.buying_unit_price.toFixed(2));
    this.selling_unit_price = movement.selling_unit_price
      ? Number(movement.selling_unit_price.toFixed(2))
      : 0;
    this.status = movement.status;
    this.memo = movement.memo;
    this.lot_id = movement.lot_id;
    this.product_id = movement.product_id;
    this.warehouse_id = movement.warehouse_id;
    this.customer_id = movement.customer_id;
    this.branch_id = movement.branch_id;
    this.stock_id = movement.stock_id;
    this.stockage_id = movement.stockage_id;
    this.customer_product_id = movement.customer_product_id;
  }

  /**
   * 主に新規作成で利用
   *
   * @param stock
   */
  public createFromStock(stock: IStock): void {
    this.stock_id = stock.id;
    this.arrival_date = stock.arrival_date;
    this.buying_unit_price = Number(stock.buying_unit_price.toFixed(2));
    this.lot_id = stock.lot_id;
    this.customer_id = stock.customer_id;
    this.product_id = stock.product_id;
    this.stockage_id = stock.stockage_id;
    this.customer_product_id = stock.customer_product_id;
    this.supplier_id = stock.supplier_id;
  }
}
