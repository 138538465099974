import dayjs from "dayjs";
import {
  IMovementStoring,
  IMovementStoringCreateRequest,
  IMovementStoringRequest,
  IMovementStoringUpdateRequest,
  IStoringDetails
} from "@/types/movement_storing";
import { Stock } from "./stock";
import { IStock } from "@/types/stock";

export class MovementStoring implements IMovementStoring {
  public id;
  public arrival_date;
  public movement_date;
  public quantity;
  public buying_unit_price;
  public status;
  public memo;
  public lot_id;
  public product_id;
  public warehouse_id;
  public customer_id;
  public branch_id;
  public stock_id;
  public stockage_id;
  public user_id;
  public lot_no;
  public warehouse_name;
  public customer_name;
  public product_name;
  public maker_id;
  public created_at;
  public updated_at;
  public deleted_at;
  public order_id;
  public order_product_id;
  public order_no;
  public customer_product_id;
  public customer_pn;
  public current_stock_quantity;
  public exchange_rate;
  public stock: IStock;
  public is_order_product_modify;
  public previos_quantity;

  public constructor(params?: IMovementStoring) {
    this.id = params ? params.id : 0;
    this.order_no = params ? params.order_no : "";
    this.arrival_date = params
      ? dayjs(params.arrival_date).format("YYYY-MM-DD")
      : "";
    this.movement_date = params
      ? dayjs(params.movement_date).format("YYYY-MM-DD")
      : "";
    this.quantity = params ? params.quantity : 0;
    this.buying_unit_price = params ? params.buying_unit_price : 0;
    this.status = params ? params.status : 0;
    this.memo = params ? params.memo : "";
    this.product_id = params ? params.product_id : 0;
    this.lot_id = params ? params.lot_id : null;
    this.warehouse_id = params ? params.warehouse_id : null;
    this.customer_id = params ? params.customer_id : null;
    this.branch_id = params ? params.branch_id : null;
    this.stock_id = params ? params.stock_id : 0;
    this.stockage_id = params ? params.stockage_id : 0;
    this.user_id = params ? params.user_id : 0;
    this.lot_no = params ? params.lot_no : "";
    this.warehouse_name = params ? params.warehouse_name : "";
    this.customer_name = params ? params.customer_name : "";
    this.product_name = params ? params.product_name : "";
    this.maker_id = params ? params.maker_id : 0;
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
    this.order_id = params ? params.order_id : 0;
    this.order_product_id = params ? params.order_product_id : 0;
    this.customer_product_id = params ? params.customer_product_id : null;
    this.customer_pn = params ? params.customer_pn : null;
    this.current_stock_quantity = params ? params.current_stock_quantity : 0;
    this.exchange_rate = params ? params.exchange_rate : null;
    this.is_order_product_modify = params
      ? params.is_order_product_modify || false
      : false;
    this.stock = params ? params.stock : new Stock();
    this.previos_quantity = params ? params.quantity : 0;
  }
}

export class MovementStoringRequest implements IMovementStoringRequest {
  public arrival_date = "";
  public status = 0;
  public supplier_id = 0;
  public product_id = 0;
  public warehouse_id: number | null = null;
  public customer_id: number | null = null;
  public branch_id: number | null = null;
  public order_product_id = 0;
  public customer_product_id: number | null = null;
  public storing_details: IStoringDetails[] = [new StoringDetails()];

  public constructor(params?: IMovementStoringRequest) {
    if (!params) {
      return;
    }
    this.arrival_date = params.arrival_date;
    this.status = params.status;
    this.product_id = params.product_id;
    this.warehouse_id = params.warehouse_id;
    this.order_product_id = params.order_product_id;
  }

  public createFromMovementStoring(movementStoring: IMovementStoring): void {
    this.arrival_date = movementStoring.arrival_date;
    this.status = movementStoring.status;
    this.product_id = movementStoring.product_id;
    this.warehouse_id = movementStoring.warehouse_id;
    this.order_product_id = movementStoring.order_product_id;
  }
}

export class MovementStoringCreateRequest
  implements IMovementStoringCreateRequest
{
  public order_id = 0;
  public supplier_id = 0;
  public status = 0;
  public exchange_rate: number | null = null;
  public storings: IMovementStoringRequest[] = [new MovementStoringRequest()];
}

export class MovementStoringUpdateRequest
  extends MovementStoringRequest
  implements IMovementStoringUpdateRequest
{
  public order_id = 0;
  public lot_id: number | null = null;
  public buying_unit_price = 0;
  public quantity = 0;
  public memo: string | null = null;
  public exchange_rate: number | null = null;
}

export class StoringDetails implements IStoringDetails {
  public lot_id: number | null = null;
  public buying_unit_price: number | null = null;
  public quantity: number | null = null;
  public transport_unit_price: number | null = null;
  public memo: string | null = null;
}
