import {
  IOrderProduct,
  IOrderProductCreateRequest,
  IOrderProductUpdateRequest
} from "@/types/order_product";
import {
  IOrder,
  IOrderCreateRequest,
  IOrderPrintRequest,
  IOrderRequest,
  IOrderUpdateRequest,
  IProductDetails,
  IStoringInfo
} from "@/types/order";
import { ISelectItem } from "@/types";
import {
  OrderProductCreateRequest,
  OrderProductUpdateRequest
} from "./order_product";
import { IMovement } from "@/types/movement";
import { IOrderPriceTag } from "@/types/order_price_tag";
import { OrderPriceTag } from "./order_price_tag";
import { Supplier } from "./supplier";
import { SupplierStaff } from "./supplier_staff";

export class Order implements IOrder {
  public id;
  public order_no;
  public order_date;
  public order_status;
  public is_asap;
  public desired_delivery_date;
  public fixed_delivery_date;
  public delivery_memo;
  public creation_date;
  public authorizer;
  public authorizer_name;
  public user_name;
  public buyer;
  public buyer_name;
  public shipped_via;
  public incoterm;
  public subtotal;
  public tax_name;
  public tax_rate;
  public amount;
  public remark;
  public our_memo;
  public destination;
  public warehouse_id;
  public supplier_id;
  public supplier_name;
  public supplier_fullname;
  public sales_staff_id;
  public sales_staff_name;
  public sales_staff_eng_name;
  public user_id;
  public short_name;
  public currency_type;
  public claim_ref_no;
  public supplier_staff;
  public customer_name;
  public misc_expense_plus_only;
  public products: IOrderProduct[];
  public order_product: IOrderProduct[];
  public storing_info: IStoringInfo[];
  public movement_list: IMovement[];
  public order_price_tag: IOrderPriceTag[];
  public supplier;
  public supplier_staff_id;
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: IOrder) {
    this.id = params ? params.id : 0;
    this.order_no = params ? params.order_no : "";
    this.order_date = params ? params.order_date : "";
    this.order_status = params ? params.order_status : 0;
    this.is_asap = params ? params.is_asap : 0;
    this.desired_delivery_date = params ? params.desired_delivery_date : "";
    this.fixed_delivery_date = params ? params.fixed_delivery_date : "";
    this.delivery_memo = params ? params.delivery_memo : "";
    this.creation_date = params ? params.creation_date : "";
    this.authorizer = params ? params.authorizer : 0;
    this.authorizer_name = params ? params.authorizer_name : "";
    this.user_name = params ? params.user_name : "";
    this.buyer = params ? params.buyer : 0;
    this.buyer_name = params ? params.buyer_name : "";
    this.shipped_via = params ? params.shipped_via : "";
    this.incoterm = params ? params.incoterm : "";
    this.subtotal = params ? params.subtotal : 0;
    this.tax_name = params ? params.tax_name : "";
    this.tax_rate = params ? params.tax_rate : 0;
    this.amount = params ? params.amount : 0;
    this.remark = params ? params.remark : "";
    this.our_memo = params ? params.our_memo : "";
    this.destination = params ? params.destination : "";
    this.warehouse_id = params ? params.warehouse_id : 0;
    this.supplier_id = params ? params.supplier_id : 0;
    this.supplier_name = params ? params.supplier_name : "";
    this.supplier_fullname = params ? params.supplier_fullname : "";
    this.sales_staff_id = params ? params.sales_staff_id : 0;
    this.sales_staff_name = params ? params.sales_staff_name : "";
    this.sales_staff_eng_name = params ? params.sales_staff_eng_name : "";
    this.user_id = params ? params.user_id : 0;
    this.short_name = params ? params.short_name : "";
    this.currency_type = params ? params.currency_type : 1;
    this.claim_ref_no = params ? params.claim_ref_no : "";
    this.supplier_staff = params ? params.supplier_staff : 0;
    this.customer_name = params ? params.customer_name : "";
    this.misc_expense_plus_only = params ? params.misc_expense_plus_only : 0;
    this.products = params ? params.products : [];
    this.order_product = params ? params.order_product : [];
    this.storing_info = params ? params.storing_info : [];
    this.movement_list = params ? params.movement_list : [];
    this.order_price_tag = params ? params.order_price_tag : [];
    this.supplier = params ? params.supplier : new Supplier();
    this.supplier_staff_id = params
      ? params.supplier_staff_id
      : new SupplierStaff();
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public static toSelectItem(order: IOrder): ISelectItem {
    return {
      text: order.order_no,
      value: order.id
    };
  }
}

export class OrderRequest implements IOrderRequest {
  public maker_id = 0;
  public order_date: string | null = null;
  public order_status = 0;
  public desired_delivery_date: string | null = null;
  public fixed_delivery_date: string | null = null;
  public delivery_memo: string | null = null;
  public creation_date: string | null = null;
  public authorizer: number | null = null;
  public buyer: number | null = null;
  public shipped_via: string | null = null;
  public incoterm: string | null = null;
  public subtotal = 0;
  public tax_name: string | null = null;
  public tax_rate = 0;
  public amount = 0;
  public remark: string | null = null;
  public our_memo: string | null = null;
  public destination: string | null = null;
  public warehouse_id: number | null = null;
  public supplier_id = 0;
  public sales_staff_id: number | null = null;
  public user_id = 0;
  public currency_type = 1;
  public claim_ref_no: string | null = null;
  public supplier_staff: number | null = null;
  public customer_name: string | null = null;
  public is_asap = 0;
  public order_price_tags: IOrderPriceTag[] = [new OrderPriceTag()];

  public createFromOrder(params: IOrder): void {
    this.maker_id = params.products[0].maker_id;
    this.delivery_memo = params.delivery_memo;
    this.is_asap = params.is_asap;
    this.shipped_via = params.shipped_via;
    this.incoterm = params.incoterm;
    this.subtotal = params.subtotal;
    this.tax_name = params.tax_name;
    this.tax_rate = params.tax_rate || 0;
    this.amount = params.amount;
    this.remark = params.remark;
    this.our_memo = params.our_memo;
    this.destination = params.destination;
    this.warehouse_id = params.warehouse_id;
    this.supplier_id = params.supplier_id;
    this.sales_staff_id = params.sales_staff_id;
    this.user_id = params.user_id;
    this.currency_type = params.currency_type;
    this.claim_ref_no = params.claim_ref_no;
    this.supplier_staff = params.supplier_staff;
    this.customer_name = params.customer_name;
    this.order_price_tags = [];

    if (params.order_price_tag && params.order_price_tag.length > 0) {
      params.order_price_tag.forEach(orderPriceTag => {
        const parmas = new OrderPriceTag();
        parmas.createFromOrderPriceTag(orderPriceTag);
        this.order_price_tags.push(parmas);
      });
    } else if (!params.order_price_tag.length) {
      this.order_price_tags.push(new OrderPriceTag());
    }
  }
}

export class OrderCreateRequest
  extends OrderRequest
  implements IOrderCreateRequest
{
  public order_products: IOrderProductCreateRequest[] = [
    new OrderProductCreateRequest()
  ];
}

export class OrderUpdateRequest
  extends OrderRequest
  implements IOrderUpdateRequest
{
  public order_no = "";
  public order_products: IOrderProductUpdateRequest[] = [
    new OrderProductUpdateRequest()
  ];
  public createFromOrder(params: IOrder): void {
    this.order_status = params.order_status;
    this.order_no = params.order_no;
    this.order_date = params.order_date;
    this.desired_delivery_date = params.desired_delivery_date;
    this.fixed_delivery_date = params.fixed_delivery_date;
    this.creation_date = params.creation_date;
    this.authorizer = params.authorizer;
    this.buyer = params.buyer;
    super.createFromOrder(params);
  }
}

export class OrderPrintRequest implements IOrderPrintRequest {
  public order_id = 0;
  public rev_no = "";
  public ship_to1 = "";
  public ship_to2 = "";
  public ship_to3 = "";
  public order_remark = "";
  public products: IProductDetails[] = [];
  public supplier: { name: string }[] = [{ name: "" }];

  public createOrderPrintRequest(params: IOrder): void {
    this.order_id = params.id;

    this.supplier[0].name = params.supplier_fullname;

    params.products.forEach(product => {
      const product_detail = {
        is_praice_tag: false,
        rev: product.revision || "",
        product_name:
          params.currency_type == 1
            ? product.product_name
            : product.product_eng_name || product.product_name,
        product_code:
          params.currency_type == 1
            ? product.product_code
            : product.product_eng_code || product.product_code,
        supplier_pn: "",
        quantity: product.quantity,
        buying_unit_price: product.buying_unit_price,
        amount: product.amount,
        product_rows: []
      };
      this.products.push(product_detail);
    });

    params.order_price_tag.forEach(price_tag => {
      const product_detail = {
        is_praice_tag: true,
        rev: "",
        product_name: price_tag.price_tag_name,
        product_code: "ー",
        supplier_pn: price_tag.supplier_pn,
        quantity: 1,
        buying_unit_price: price_tag.price,
        amount: price_tag.price || 0,
        product_rows: []
      };
      this.products.push(product_detail);
    });
  }
}
