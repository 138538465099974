import axios from "axios";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  IReceivedOrdersManagementListRequest,
  IReceivedOrdersManagementUpdateRequest,
  ReceivedOrdersManagementState,
  IReceivedOrdersManagement
} from "@/types/received_orders_management";

export const actions: ActionTree<ReceivedOrdersManagementState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: IReceivedOrdersManagementListRequest) {
    try {
      const response = await axios.get<IPagination<IReceivedOrdersManagement>>(
        "/admin/received_orders_management",
        {
          params
        }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, received_orders_management_id: number) {
    try {
      const response = await axios.get<IReceivedOrdersManagement>(
        `/admin/received_orders_management/${received_orders_management_id}`
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: {
      received_orders_management_id: number;
      params: IReceivedOrdersManagementUpdateRequest;
    }
  ) {
    try {
      const response = await axios.patch<IReceivedOrdersManagement>(
        `/admin/received_orders_management/${data.received_orders_management_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  async adminCreate({ commit }) {
    try {
      const response = await axios.post<IReceivedOrdersManagement>(
        "/admin/received_orders_management"
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  async adminDelete({ commit }, received_orders_management_id: number) {
    try {
      await axios.delete(
        `/admin/received_orders_management/${received_orders_management_id}`
      );
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: IReceivedOrdersManagementListRequest) {
    try {
      const response = await axios.get<IPagination<IReceivedOrdersManagement>>(
        "/user/received_orders_management",
        {
          params
        }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, received_orders_management_id: number) {
    try {
      const response = await axios.get<IReceivedOrdersManagement>(
        `/user/received_orders_management/${received_orders_management_id}`
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: {
      received_orders_management_id: number;
      params: IReceivedOrdersManagementUpdateRequest;
    }
  ) {
    try {
      const response = await axios.patch<IReceivedOrdersManagement>(
        `/user/received_orders_management/${data.received_orders_management_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  async userCreate({ commit }) {
    try {
      const response = await axios.post<IReceivedOrdersManagement>(
        "/user/received_orders_management"
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  async userDelete({ commit }, received_orders_management_id: number) {
    try {
      await axios.delete(
        `/user/received_orders_management/${received_orders_management_id}`
      );
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  }
};

export default actions;
