import Vue from "vue";
import axios from "axios";
import { ActionTree } from "vuex";
import { RootState } from "@/types";
import {
  IMoneyforward,
  IMoneyforwardDepartmentCreateRequest,
  IMoneyforwardDepartmentUpdateRequest,
  IMoneyforwardPartnerUpdateRequest,
  IMoneyforwardToken,
  MoneyforwardState
} from "@/types/moneyforward";

export const actions: ActionTree<MoneyforwardState, RootState> = {
  // DBからリフレッシュトークン取得
  async getRefreshToken({ commit }) {
    try {
      const response = await axios.get<IMoneyforwardToken>(`/mf_token`);

      localStorage.setItem("mf_refresh_token", response.data.refresh_token);

      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:MF連携を行ってください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  // DBにリフレッシュトークン保存
  async createRefreshToken({ commit }) {
    const params = { refresh_token: localStorage.getItem("mf_refresh_token") };
    try {
      await axios.post<IMoneyforwardToken>("/mf_token", params);

      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:MF連携を行ってください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  // アクセストークン、リフレッシュトークンの初回取得
  async getToken({ commit }, code) {
    const formData = new URLSearchParams();
    formData.append("grant_type", "authorization_code");
    formData.append("code", code);

    try {
      const response = await axios.post<IMoneyforwardToken>("/token", formData);
      localStorage.setItem("mf_access_token", response.data.access_token);
      localStorage.setItem("mf_refresh_token", response.data.refresh_token);

      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:MF連携を行ってください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  // リフレッシュトークンを利用したアクセストークンの再発行
  async getReToken({ commit }) {
    const formData = new URLSearchParams();
    formData.append("grant_type", "refresh_token");
    formData.append(
      "refresh_token",
      localStorage.getItem("mf_refresh_token") || ""
    );

    try {
      const response = await axios.post<IMoneyforwardToken>("/token", formData);

      localStorage.setItem("mf_access_token", response.data.access_token);
      localStorage.setItem("mf_refresh_token", response.data.refresh_token);

      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:MF連携を行ってください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  // admin---------------------------------------------------------------------
  // 顧客及び支店登録
  async adminCreatePartnerAndDepartment({ commit }, params) {
    const accessToken = localStorage.getItem("mf_access_token");
    params.access_token = accessToken;
    try {
      const response = await axios.post<IMoneyforward>(
        "admin/customer/partner",
        params
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  // 顧客編集
  async adminUpdatePartner(
    { commit },
    data: {
      partner_id: number;
      params: IMoneyforwardPartnerUpdateRequest;
      access_token: string | null;
    }
  ) {
    const accessToken = localStorage.getItem("mf_access_token");
    data.access_token = accessToken;

    try {
      const response = await axios.put<IMoneyforward>(
        `admin/customer/partner/${data.partner_id}`,
        data
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  // 顧客削除
  async adminDeletePartner({ commit }, partner_id) {
    const accessToken = localStorage.getItem("mf_access_token");
    try {
      await axios.delete<IMoneyforward>(
        `admin/customer/partner/${partner_id}`,
        {
          params: {
            access_token: accessToken
          }
        }
      );

      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //支店登録
  async adminCreateDepartment(
    { commit },
    data: {
      partner_id: number;
      params: IMoneyforwardDepartmentCreateRequest;
      access_token: string | null;
    }
  ) {
    const accessToken = localStorage.getItem("mf_access_token");
    data.access_token = accessToken;

    try {
      const response = await axios.post<IMoneyforward>(
        "/admin/branch/department",
        data,
        {}
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //支店編集
  async adminUpdateDepartment(
    { commit },
    data: {
      partner_id: number;
      department_id: number;
      params: IMoneyforwardDepartmentUpdateRequest;
      access_token: string | null;
    }
  ) {
    const accessToken = localStorage.getItem("mf_access_token");
    data.access_token = accessToken;

    try {
      const response = await axios.put<IMoneyforward>(
        "/admin/branch/department",
        data,
        {}
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  // 支店削除
  async adminDeleteDepartment(
    { commit },
    data: {
      partner_id: string;
      department_id: string;
      access_token: string | null;
    }
  ) {
    const accessToken = localStorage.getItem("mf_access_token");
    data.access_token = accessToken;

    try {
      await axios.delete<IMoneyforward>("/admin/branch/department", {
        data: data
      });

      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },
  // ----------------------------------------------------------------------------------

   // user---------------------------------------------------------------------
  // 顧客及び支店登録
  async userCreatePartnerAndDepartment({ commit }, params) {
    const accessToken = localStorage.getItem("mf_access_token");
    params.access_token = accessToken;
    try {
      const response = await axios.post<IMoneyforward>(
        "user/customer/partner",
        params
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  // 顧客編集
  async userUpdatePartner(
    { commit },
    data: {
      partner_id: number;
      params: IMoneyforwardPartnerUpdateRequest;
      access_token: string | null;
    }
  ) {
    const accessToken = localStorage.getItem("mf_access_token");
    data.access_token = accessToken;

    try {
      const response = await axios.put<IMoneyforward>(
        `user/customer/partner/${data.partner_id}`,
        data
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //支店登録
  async userCreateDepartment(
    { commit },
    data: {
      partner_id: number;
      params: IMoneyforwardDepartmentCreateRequest;
      access_token: string | null;
    }
  ) {
    const accessToken = localStorage.getItem("mf_access_token");
    data.access_token = accessToken;

    try {
      const response = await axios.post<IMoneyforward>(
        "/user/branch/department",
        data,
        {}
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //支店編集
  async userUpdateDepartment(
    { commit },
    data: {
      partner_id: number;
      department_id: number;
      params: IMoneyforwardDepartmentUpdateRequest;
      access_token: string | null;
    }
  ) {
    const accessToken = localStorage.getItem("mf_access_token");
    data.access_token = accessToken;

    try {
      const response = await axios.put<IMoneyforward>(
        "/user/branch/department",
        data,
        {}
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },
  // ----------------------------------------------------------------------------------
};

export default actions;
