import {
  IProductReceivedOrder,
  IProductReceivedOrderRequest
} from "@/types/product_received_order";
import {
  IProductNameArray,
  IReceivedOrder,
  IReceivedOrderCreateRequest,
  IReceivedOrderRequest,
  IReceivedOrderUpdateRequest
} from "@/types/received_order";
import { IOrders } from "@/types/orders";
import { ISelectItem } from "@/types";
import { ProductReceivedOrderRequest } from "./product_received_order";
import { IShipment } from "@/types/shipment";
import { IBranch } from "@/types/branch";
import { Branch } from "./branch";
import { IPriceTagReceivedOrder } from "@/types/price_tag_received_order";
import { PriceTagReceivedOrder } from "./price_tag_received_order";
import { Orders } from "./orders";

export class ReceivedOrder implements IReceivedOrder {
  public id;
  public order_date;
  public received_orders_management_id;
  public management_no;
  public order_no;
  public order_status;
  public is_asap;
  public desired_delivery_date;
  public delivery_memo;
  public fixed_delivery_date;
  public destination;
  public amount;
  public remark;
  public our_memo;
  public customer_id;
  public branch_id;
  public customer_staff_id;
  public user_id;
  public user_name;
  public short_name;
  public branch_short_name;
  public is_remaining_shipment;
  public products: IProductReceivedOrder[];
  public product_received_orders: IProductReceivedOrder[];
  public product_received_order: IProductReceivedOrder[];
  public product_name_array: IProductNameArray[];
  public orders: IOrders[];
  public shipment: IShipment[];
  public price_tag_received_order: IPriceTagReceivedOrder[];
  public branch: IBranch;
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: IReceivedOrder) {
    this.id = params ? params.id : 0;
    this.order_date = params ? params.order_date : "";
    this.received_orders_management_id = params
      ? params.received_orders_management_id
      : 0;
    this.management_no = params ? params.management_no : "";
    this.order_no = params ? params.order_no : "";
    this.order_status = params ? params.order_status : 0;
    this.is_asap = params ? params.is_asap : 0;
    this.desired_delivery_date = params ? params.desired_delivery_date : "";
    this.delivery_memo = params ? params.delivery_memo : "";
    this.fixed_delivery_date = params ? params.fixed_delivery_date : "";
    this.destination = params ? params.destination : "";
    this.amount = params ? params.amount : 0;
    this.remark = params ? params.remark : "";
    this.our_memo = params ? params.our_memo : "";
    this.customer_id = params ? params.customer_id : 0;
    this.branch_id = params ? params.branch_id : null;
    this.customer_staff_id = params ? params.customer_staff_id : null;
    this.user_id = params ? params.user_id : 0;
    this.user_name = params ? params.user_name : "";
    this.short_name = params ? params.short_name : "";
    this.branch_short_name = params ? params.branch_short_name : "";
    this.is_remaining_shipment = params ? params.is_remaining_shipment : true;
    this.orders = params ? params.orders : [];
    this.shipment = params ? params.shipment : [];
    this.price_tag_received_order = params
      ? params.price_tag_received_order
      : [];
    this.branch = params ? params.branch : new Branch();
    this.products = params ? params.products : [];
    this.product_received_orders = params ? params.product_received_orders : [];
    this.product_received_order = params ? params.product_received_order : [];
    this.product_name_array = params ? params.product_name_array : [];
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public static toSelectItem(received_order: IReceivedOrder): ISelectItem {
    return {
      text: received_order.order_no,
      value: received_order.id
    };
  }
}

export class ReceivedOrderRequest implements IReceivedOrderRequest {
  public received_orders_management_id = 0;
  public order_date: string | null = null;
  public order_status = 0;
  public fixed_delivery_date: string | null = null;
  public destination: string | null = null;
  public amount = 0;
  public remark: string | null = null;
  public our_memo: string | null = null;
  public customer_id = 0;
  public branch_id: number | null = null;
  public customer_staff_id: number | null = null;

  public craetateFromReceivedOrder(param: IReceivedOrder) {
    this.received_orders_management_id = param.received_orders_management_id;
    this.order_date = param.order_date;
    this.order_status = param.order_status;
    this.fixed_delivery_date = param.fixed_delivery_date;
    this.destination = param.destination;
    this.amount = param.amount;
    this.remark = param.remark;
    this.our_memo = param.our_memo;
    this.customer_id = param.customer_id;
    this.branch_id = param.branch_id;
    this.customer_staff_id = param.customer_staff_id;
  }
}

export class ReceivedOrderUpdateRequest
  extends ReceivedOrderRequest
  implements IReceivedOrderUpdateRequest
{
  public order_no = "";
  public is_asap = 0;
  public desired_delivery_date: string | null = null;
  public delivery_memo: string | null = null;
  public product_received_orders: IProductReceivedOrderRequest[] = [];
  public price_tag_received_orders: IPriceTagReceivedOrder[] = [];
  public user_id = 0;

  public craetateFromReceivedOrder(param: IReceivedOrder) {
    this.order_no = param.order_no;
    this.is_asap = param.is_asap;
    this.desired_delivery_date = param.desired_delivery_date;
    this.delivery_memo = param.delivery_memo;
    this.user_id = param.user_id;
    this.product_received_orders = [];
    this.price_tag_received_orders = [];

    if (param.products && param.products.length > 0) {
      param.products.forEach(productReceivedOrder => {
        const parmas = new ProductReceivedOrderRequest();
        parmas.createFromPrductReceivedOrder(productReceivedOrder);
        this.product_received_orders.push(parmas);
      });
    }

    if (
      param.price_tag_received_order &&
      param.price_tag_received_order.length > 0
    ) {
      param.price_tag_received_order.forEach(priceTagReceivedOrder => {
        const parmas = new PriceTagReceivedOrder();
        parmas.createFromPriceTagReceivedOrder(priceTagReceivedOrder);
        this.price_tag_received_orders.push(parmas);
      });
    } else if (!param.price_tag_received_order.length) {
      this.price_tag_received_orders.push(new PriceTagReceivedOrder());
    }

    super.craetateFromReceivedOrder(param);
  }
}

export class ReceivedOrderCreateRequest
  extends ReceivedOrderRequest
  implements IReceivedOrderCreateRequest
{
  public orders: IOrders[] = [new Orders()];
}
